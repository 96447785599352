import { Checkbox } from "@mantine/core";
import { useState } from "react";
import { useUpdateAttendee } from "../../api/attendees";

function PaidCheckbox({ checked, attendeeId }) {
  const [isChecked, setIsChecked] = useState(checked);

  const { mutate } = useUpdateAttendee({ attendeeId });

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    mutate({ paid: !isChecked }, { onError: () => setIsChecked(isChecked) });
  };

  return (
    <Checkbox
      checked={isChecked}
      onChange={() => {
        handleCheckboxChange();
      }}
    />
  );
}

export default PaidCheckbox;
