import { useApiMutation, useApiQuery } from "../services/react-query";

export const useGetAllAnnouncements = (params) => {
  return useApiQuery({
    queryOptions: { queryKey: ["announcements", params] },
    fetchOptions: {
      url: "/announcements",
      method: "GET",
      params,
    },
  });
};

export const useGetAnnouncement = (announcementId) => {
  return useApiQuery({
    queryOptions: { queryKey: ["announcements", announcementId] },
    fetchOptions: {
      url: `/announcements/${announcementId}`,
      method: "GET",
    },
  });
};

export const useCreateAnnouncement = () => {
  return useApiMutation({
    queryOptions: { queryKey: ["announcements"] },
    fetchOptions: {
      url: "/announcements",
      method: "POST",
    },
    successMessage: {
      title: "Announcement was created successfully",
    },
  });
};

export const useUpdateAnnouncement = (announcementId) => {
  return useApiMutation({
    queryOptions: { queryKey: ["announcements"] },
    fetchOptions: {
      url: `/announcements/${announcementId}`,
      method: "PATCH",
    },
    successMessage: {
      title: "Announcement was updated successfully",
    },
    invalidateQueryKey: ["announcements"],
  });
};

export const useDeleteAnnouncement = () => {
  return useApiMutation({
    queryOptions: { queryKey: ["announcements"] },
    fetchOptions: {
      url: "/announcements",
      method: "DELETE",
    },
    successMessage: {
      title: "Announcement was deleted successfully",
    },
  });
};

export const useNotifyAnnouncement = () => {
  return useApiMutation({
    fetchOptions: {
      url: "/announcements/notify",
      method: "POST",
    },
    successMessage: {
      title: "Notification was sent successfully",
    },
  });
};
