import { ActionIcon, Group, Image, Text, Tooltip } from "@mantine/core";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import { DataTable } from "mantine-datatable";
import { Link } from "react-router-dom";
import { useDeleteSponsor } from "../../api/sponsors";
import deleteModal from "../deleteModal";

function SponsorsTable({
  data,
  isLoading,
  page,
  setPage,
  sortStatus,
  setSort,
}) {
  const { mutate, isSuccess, isError, error } = useDeleteSponsor();

  return (
    <>
      <DataTable
        records={data?.data}
        columns={[
          { accessor: "name", sortable: true },
          { accessor: "subtitle" },
          { accessor: "description" },
          {
            accessor: "logo",
            render: (record) => {
              if (record.logo) {
                return <Image h={30} w={30} fit="contain" src={record.logo} />;
              }
              return <Text ta="center">/</Text>;
            },
          },
          {
            accessor: "links",
            render: (sponsor) =>
              sponsor.links.map((link, index) => {
                return (
                  <Group key={link.url}>
                    <Text
                      size="sm"
                      style={{
                        textDecorationLine: "underline",
                      }}
                      component={Link}
                      to={
                        link?.url.startsWith("http://") ||
                        link?.url.startsWith("https://")
                          ? link?.url
                          : `//${link?.url}`
                      }
                      pb={10}
                    >
                      {link.label}
                    </Text>
                  </Group>
                );
              }),
          },
          {
            accessor: "actions",
            width: 100,
            render: (sponsor) => (
              <Group justify="center">
                <Tooltip label="Edit">
                  <ActionIcon
                    size="md"
                    variant="light"
                    component={Link}
                    to={`${sponsor.id}`}
                  >
                    <IconEdit />
                  </ActionIcon>
                </Tooltip>
                <Tooltip label="Delete">
                  <ActionIcon
                    size="md"
                    variant="light"
                    color="red"
                    onClick={() =>
                      deleteModal(
                        sponsor,
                        `delete '${sponsor.name}' sponsor`,
                        mutate,
                        isSuccess,
                        isError,
                        error
                      )
                    }
                  >
                    <IconTrash />
                  </ActionIcon>
                </Tooltip>
              </Group>
            ),
          },
        ]}
        withTableBorder
        striped
        withColumnBorders
        highlightOnHover
        page={page}
        onPageChange={setPage}
        recordsPerPage={data?.pagination.perPage}
        totalRecords={data?.pagination.total}
        fetching={isLoading}
        sortStatus={sortStatus}
        onSortStatusChange={setSort}
      />
    </>
  );
}

export default SponsorsTable;
