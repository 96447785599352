import { useParams } from "react-router-dom";
import { ajvResolver } from "@hookform/resolvers/ajv";
import { Button, Container, Image, Text, Title } from "@mantine/core";
import { useForm } from "react-hook-form";
import { PasswordInput } from "react-hook-form-mantine";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { modals } from "@mantine/modals";

import { useGetUserByInviteToken, useSetPassword } from "../../api/auth";
import Logo from "../../assets/logo-02.svg";
import LoadingPage from "../common/LoadingPage";

function Register() {
  const token = useParams().token;
  const navigate = useNavigate();

  const [data, setData] = useState({
    password: "",
    confirmPassword: "",
  });
  const [disableForm, setDisableForm] = useState(false);

  const {
    data: userData,
    isSuccess,
    isError,
    isLoading,
  } = useGetUserByInviteToken(token);

  // Check if the token is expired or password is already set
  const notValid =
    userData?.status === "password set" || userData?.status === "token expired";

  const { mutate: setPassword } = useSetPassword({
    user: userData,
    password: data?.password,
  });

  const errorMessage = isError
    ? "Invalid token."
    : userData?.status === "password set"
      ? "Password already set."
      : userData?.status === "token expired"
        ? "Token expired."
        : null;

  const openModal = (mutate, user, password) => {
    modals.openConfirmModal({
      title: "Save password?",
      centered: true,
      children: <Text size="sm">Please confirm to proceed.</Text>,
      labels: { confirm: "Confirm", cancel: "Cancel" },
      onConfirm: () => {
        mutate({ user, password });
        navigate("/");
        modals.close();
      },
      onCancel: () => {
        modals.close();
      },
    });
  };

  useEffect(() => {
    if (isError || (isSuccess && notValid)) {
      setDisableForm(true);
    }
  }, [userData, isSuccess, isError]);

  const { control, handleSubmit } = useForm({
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
    resolver: ajvResolver(
      {
        type: "object",
        properties: {
          password: {
            type: "string",
            minLength: 3,
            pattern: "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d).{8,}$",
            // Require lowercase, uppercase, and digit
          },
          confirmPassword: {
            type: "string",
            const: { $data: "1/password" },
            // Ensure confirmPassword matches password
          },
        },
        required: ["password", "confirmPassword"],
        errorMessage: {
          properties: {
            password:
              "Password must contain at least 8 characters, a lowercase, an uppercase, and a digit",
            confirmPassword: "Passwords do not match",
          },
        },
      },
      { $data: true }
    ),
  });

  const onSubmit = (data) => {
    setData(data);
    openModal(setPassword, userData, data.password);
  };

  if (isLoading) {
    return <LoadingPage />;
  }
  return (
    <Container size={420}>
      <Image src={Logo} alt="congrs logo" />
      <Title>Registration</Title>
      <Text>Please set your password to register.</Text>
      <form onSubmit={handleSubmit((data) => onSubmit(data))}>
        <PasswordInput
          label="Set Password"
          placeholder="Enter your password"
          name="password"
          control={control}
          withAsterisk
          mt="md"
          disabled={disableForm}
        />
        <PasswordInput
          label="Confirm Password"
          placeholder="Confirm your password"
          name="confirmPassword"
          control={control}
          withAsterisk
          mt="md"
          disabled={disableForm}
        />
        <Button
          type="submit"
          variant="gradient"
          fullWidth
          size="md"
          my={10}
          disabled={disableForm}
        >
          Register
        </Button>

        {disableForm && (
          <Text c="red" align="center">
            {errorMessage}
          </Text>
        )}
      </form>
    </Container>
  );
}

export default Register;
