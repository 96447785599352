// Define environment values
const envValues = {
  local: "local",
  development: "development",
  production: "production",
};

// Define common configurations
const commonConfig = {
  posthog: {
    key: "phc_BweFCepcCq0UckpOWouvf7EHn70MgMqh8LhGSRphag9",
    host: "https://eu.posthog.com",
  },
  r2: {
    myBucket: "congrs",
  },
  OAuth: {
    webClientId:
      "224661272614-53orvlesf9tp8hl6r1nldor9nc3i0dch.apps.googleusercontent.com",
  },
  // Sentry dsn and token in env
};

// Define base configurations for each environment
const makeBaseConfig = () => ({
  local: {
    baseUrl: "http://localhost:5173",
    apiUrl: "http://localhost:3000/api",
  },
  development: {
    baseUrl: "https://app.dev.congrs.com",
    apiUrl: "http://app.dev.congrs.com/api",
  },
  production: {
    baseUrl: "https://app.congrs.com",
    apiUrl: "https://app.congrs.com/api",
  },
});

// Initialize configuration based on environment
const environment = process.env.CLOUD_ENV || envValues.local;

const baseConfig = makeBaseConfig();
export const webConfig = { ...baseConfig[environment], ...commonConfig };
