import { ajvResolver } from "@hookform/resolvers/ajv";
import {
  Button,
  Container,
  Fieldset,
  MultiSelect,
  Paper,
  Select,
  Text,
  TextInput as TextInputMantine,
} from "@mantine/core";
import "@mantine/dates/styles.css";
import { IconPlus, IconX } from "@tabler/icons-react";
import { useContext, useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { TextInput, DateTimePicker } from "react-hook-form-mantine";
import { fullFormats } from "ajv-formats/dist/formats";

import { useCreateSession } from "../../api/session";
import { useGetSponsors } from "../../api/sponsors";
import { useGetUsers } from "../../api/user";
import { AuthContext } from "../../services/context";
import { getCurrentWorkspace } from "../../services/userConfig";
import { formatDateTime } from "./utils/formatDateTime";
import { useGetSessionTagsByEventId } from "../../api/tag";
import { formatTagLabel } from "./utils/formatTagLabel";

export default function CreateSession() {
  const { user } = useContext(AuthContext);
  const {
    data: userData,
    isPending: userPending,
    isError: userIsError,
    error: userError,
  } = useGetUsers();

  const currentWorkspace = getCurrentWorkspace(user);

  const {
    data: sponsors,
    isSuccess: sponsorsSuccess,
    isError: sponsorsIsError,
    error: sponsorsError,
  } = useGetSponsors({
    eventId: currentWorkspace.eventId,
  });

  const { mutate, isPending, isSuccess } = useCreateSession();

  let sponsorsOptions;
  if (sponsorsSuccess) {
    sponsorsOptions = sponsors.data.map((s) => ({
      label: s.name,
      value: s.id.toString(),
    }));
  }

  const { data: sessionTags, isSuccess: tagsSuccess } =
    useGetSessionTagsByEventId(currentWorkspace.eventId);

  const [tagOptions, setTagOptions] = useState({
    dressCode: [],
    sessionType: [],
    topic: [],
  });

  useEffect(() => {
    if (tagsSuccess) {
      const getTagOptions = (subtype) =>
        sessionTags
          .filter((tag) => tag.subtype === subtype)
          .map((tag) => ({ label: tag.title, value: tag.id.toString() }));

      setTagOptions({
        dressCode: getTagOptions("dressCode"),
        sessionType: getTagOptions("sessionType"),
        topic: getTagOptions("topic"),
      });
    }
  }, [tagsSuccess, sessionTags]);

  const enableActions = user?.role === "sysAdmin" || user?.role === "wsAdmin";

  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);

  const { register, control, handleSubmit, setValue } = useForm({
    defaultValues: {
      title: "",
      description: "",
      location: "",
      starting: null,
      ending: null,
      speakers: [],
      sponsorId: "",
      links: "",
      tags: {
        dressCode: [],
        sessionType: [],
        topic: [],
      },
    },
    resolver: ajvResolver(
      {
        type: "object",
        properties: {
          title: { type: "string", minLength: 1 },
          location: { type: "string", minLength: 3 },
          starting: {
            type: ["string", "object"],
            format: "date-time",
            errorMessage: "Starting date is required",
          },
          ending: {
            type: ["string", "object"],
            format: "date-time",
            errorMessage: "Ending date is required",
          },
          tags: {
            type: "object",
            properties: {
              dressCode: {
                type: "array",
                items: { type: "string" },
              },
              sessionType: {
                type: "array",
                items: { type: "string" },
              },
              topic: {
                type: "array",
                items: { type: "string" },
              },
            },
          },
        },
        required: ["title", "location", "starting", "ending"],
      },
      { formats: fullFormats, allowUnionTypes: true }
    ),
  });

  const { fields, append, remove } = useFieldArray({
    name: "links",
    control,
  });

  const onSubmit = (data) => {
    data.starting = formatDateTime(start.toString());
    data.ending = formatDateTime(end.toString());
    data.wsId = currentWorkspace.id;
    data.eventId = currentWorkspace.eventId;
    data.sponsorId = data.sponsorId ? Number(data.sponsorId) : null;

    data.speakers = { list: data.speakers.map((s) => Number(s)) };
    data.links = { list: data.links };

    data.tags = ["dressCode", "sessionType", "topic"]
      .reduce((acc, type) => acc.concat(data.tags[type] || []), [])
      .map((tag) => Number(tag));

    mutate(data);
  };

  if (userPending) {
    return <Text>Loading...</Text>;
  }
  if (userIsError) {
    return <Text>Error while getting user data: {userError}</Text>;
  }
  if (sponsorsIsError) {
    return <Text>Error while getting sponsors data: {sponsorsError}</Text>;
  }

  return (
    <Container size={500}>
      <Paper withBorder shadow="md" p={16} mt={16} radius="md">
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextInput
            label="Title"
            placeholder="Title"
            type="text"
            name="title"
            control={control}
            withAsterisk
            mt="md"
            disabled={!enableActions}
          />
          <TextInput
            label="Description"
            placeholder="Description"
            type="text"
            name="description"
            control={control}
            mt="md"
            disabled={!enableActions}
          />
          <TextInput
            label="Location"
            placeholder="Location"
            type="text"
            name="location"
            control={control}
            withAsterisk
            mt="md"
            disabled={!enableActions}
          />
          <DateTimePicker
            label="Starting"
            placeholder="Starting"
            name="starting"
            onChange={setStart}
            withAsterisk
            mt="md"
            clearable
            disabled={!enableActions}
            minDate={new Date()}
            control={control}
          />
          <DateTimePicker
            label="Ending"
            placeholder="Ending"
            name="ending"
            onChange={setEnd}
            withAsterisk
            mt="md"
            clearable
            disabled={!enableActions}
            minDate={new Date(start)}
            control={control}
          />
          <MultiSelect
            checkIconPosition="left"
            data={userData?.slice(1).map((speaker) => ({
              label: `${speaker.firstName} ${speaker.lastName}, ${speaker.occupation}`,
              value: speaker.id.toString(),
            }))}
            label="Speakers"
            name="speakers"
            placeholder="Add speaker"
            mt="md"
            searchable
            nothingFoundMessage="Nothing found"
            onChange={(value) =>
              setValue("speakers", value, { shouldDirty: true })
            }
          />
          <Select
            label="Sponsor"
            name="sponsorId"
            control={control}
            placeholder="Add sponsor"
            data={sponsorsOptions}
            clearable
            searchable
            mt="md"
            onChange={(value) =>
              setValue("sponsorId", Number(value), { shouldDirty: true })
            }
          />

          <Fieldset legend="Links" mt="md">
            {fields.map((field, index) => (
              <Fieldset mt="md" key={field.id}>
                <TextInputMantine
                  label="Link title"
                  {...register(`links.${index}.title`)}
                />
                <TextInputMantine
                  label="Link URL"
                  {...register(`links.${index}.url`)}
                />

                <Button
                  mt={5}
                  size="xs"
                  style={{ backgroundColor: "red" }}
                  onClick={() => remove(index)}
                  leftSection={<IconX size={20} />}
                >
                  Remove link
                </Button>
              </Fieldset>
            ))}

            <Button
              mt={5}
              size="xs"
              style={{ justifySelf: "flex-end" }}
              onClick={() => append()}
              leftSection={<IconPlus size={20} />}
            >
              Add link
            </Button>
          </Fieldset>

          {sessionTags && (
            <Fieldset legend="Tags" mt="md">
              {["dressCode", "sessionType", "topic"].map((type) => (
                <MultiSelect
                  key={type}
                  label={`Tags - ${formatTagLabel(type)}`}
                  name={`tags.${type}`}
                  placeholder={`Select ${formatTagLabel(type)} tags`}
                  data={tagOptions[type] || []}
                  mt="md"
                  searchable
                  nothingFoundMessage="No tags found"
                  clearable
                  onChange={(value) =>
                    setValue(`tags.${type}`, value, { shouldDirty: true })
                  }
                />
              ))}
            </Fieldset>
          )}

          {!enableActions && (
            <Text size="md" c="red" mt="md">
              Only system admins and workspace admins can create new sessions!
            </Text>
          )}

          <Button
            type="submit"
            mt="md"
            loading={isPending}
            disabled={!enableActions || isSuccess}
          >
            Create session
          </Button>
        </form>
      </Paper>
    </Container>
  );
}
