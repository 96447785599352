import { useState, useContext } from "react";
import AttendeesTable from "../components/Attendees/Table";
import { useGetAllAttendees } from "../api/attendees";
import { AuthContext } from "../services/context";
import { getCurrentWorkspace } from "../services/userConfig";

function Attendees() {
  const { user } = useContext(AuthContext);
  const currentWorkspace = getCurrentWorkspace(user);
  const currentEventId = currentWorkspace.eventId;

  const [pagination, setPagination] = useState({ page: 1, limit: 10 });
  const [sorting, setSorting] = useState({
    columnAccessor: "firstName",
    direction: "asc",
  });
  const { data, isPending } = useGetAllAttendees({
    eventId: currentEventId,
    ...pagination,
    sort: sorting,
  });

  return (
    <AttendeesTable
      data={data}
      pagination={{ data: pagination, setPagination }}
      sorting={{ data: sorting, setSorting }}
      isPending={isPending}
    />
  );
}

export default Attendees;
