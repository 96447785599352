import { DEFAULT_THEME } from "@mantine/core";
const getLimitedSwatches = (colorArray, limit) => {
  return colorArray.slice(-limit);
};

const swatchesLimit = 5;
const colorKeys = ["yellow", "red", "blue", "green", "violet", "cyan"];

export const colorSwatches = colorKeys.flatMap((colorKey) =>
  getLimitedSwatches(DEFAULT_THEME.colors[colorKey], swatchesLimit)
);
