import { Container, Title, Loader } from "@mantine/core";
import { useParams } from "react-router-dom";
import {
  useGetAllAnnouncements,
  useGetAnnouncement,
} from "../../api/announcements";
import EditAnnouncementForm from "./EditAnnouncementForm";

const EditAnnouncement = () => {
  const { announcementId } = useParams();

  const { data, isPending, isSuccess } = useGetAnnouncement(announcementId);

  const { data: allAnnouncements } = useGetAllAnnouncements({
    eventId: data?.eventId,
    page: 1,
    limit: 20,
  });

  const moreThanOneVisible =
    allAnnouncements?.data.filter((announcement) => announcement.isVisible)
      .length >= 1;

  return (
    <Container size={500}>
      <Title order={1}>Create Announcement</Title>
      {isPending && <Loader />}
      {isSuccess && (
        <EditAnnouncementForm
          data={data}
          moreThanOneVisible={moreThanOneVisible}
        />
      )}
    </Container>
  );
};

export default EditAnnouncement;
