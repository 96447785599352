import { AppShell } from "@mantine/core";
import { useContext } from "react";
import { useDisclosure } from "@mantine/hooks";
import { Outlet } from "react-router-dom";

import Header from "../components/Header/Header";
import Navbar from "../components/Navbar/Navbar";
import { AuthContext } from "../services/context";

export default function App() {
  const { user } = useContext(AuthContext);
  const isAuthenticated = !!user;
  const [opened, { toggle }] = useDisclosure();

  return (
    <AppShell
      navbar={{
        width: 280,
        breakpoint: "sm",
        collapsed: { mobile: !opened },
      }}
      padding="md"
      header={{
        height: 60,
      }}
      layout="alt"
    >
      <Header opened={opened} toggle={toggle} />
      <Navbar
        opened={opened}
        toggle={toggle}
        user={user}
        isAuthenticated={isAuthenticated}
      />

      <AppShell.Main>
        <Outlet />
      </AppShell.Main>
    </AppShell>
  );
}
