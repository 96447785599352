import { Button, Container, Group, Image as MantineImage } from "@mantine/core";
import { useState } from "react";
import Cropper from "react-easy-crop";
import { modals } from "@mantine/modals";

const getCroppedImg = async (imageSrc, croppedArea) => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  const image = new Image();
  image.src = imageSrc;
  await new Promise((resolve) => {
    image.onload = resolve;
  });

  canvas.width = 1920;
  canvas.height = 1080;

  ctx.drawImage(
    image,
    croppedArea.x,
    croppedArea.y,
    croppedArea.width,
    croppedArea.height,
    0,
    0,
    canvas.width,
    canvas.height
  );

  return new Promise((resolve) => {
    canvas.toBlob((blob) => {
      resolve(blob);
    }, "blob");
  });
};

const CropImage = ({ imageSrc, imageName, setNewHeaderImage }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedArea, setCroppedArea] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);

  const onCropComplete = (croppedAreaPixels, croppedArea) => {
    setCroppedAreaPixels(croppedAreaPixels);
    setCroppedArea(croppedArea);
  };

  const handleSave = async () => {
    if (!croppedArea) return;

    try {
      const croppedImage = await getCroppedImg(imageSrc, croppedArea, zoom);

      const file = new File([croppedImage], imageName, {
        type: "image/jpeg",
      });

      setNewHeaderImage(file);
      modals.closeAll();
    } catch (error) {
      console.error("Error cropping image:", error);
    }
  };

  const handlePreview = async () => {
    if (!croppedArea) return;

    try {
      const croppedImage = await getCroppedImg(imageSrc, croppedArea, zoom);

      const file = new File([croppedImage], imageName, {
        type: "image/jpeg",
      });

      setPreviewImage(URL.createObjectURL(file));
    } catch (error) {
      console.error("Error cropping image:", error);
    }
  };

  return (
    <>
      <Group
        style={{ flexDirection: "row", gap: 5, justifyContent: "flex-end" }}
      >
        <Container
          style={{
            width: 500,
            height: 281,
            position: "relative",
          }}
        >
          <Cropper
            image={imageSrc}
            crop={crop}
            zoom={zoom}
            aspect={16 / 9}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={onCropComplete}
          />
        </Container>
        <Container
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <input
            type="range"
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"
            onChange={(e) => {
              setZoom(e.target.value);
            }}
          />
        </Container>

        {previewImage && <MantineImage src={previewImage} />}
        <Button onClick={handlePreview}>Preview</Button>
        <Button onClick={handleSave}>Save</Button>
      </Group>
    </>
  );
};
export default CropImage;
