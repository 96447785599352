import { Container, Paper, Title, Button, Text } from "@mantine/core";
import { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Select, TextInput } from "react-hook-form-mantine";

import { AuthContext } from "../../services/context";
import { getCurrentWorkspace } from "../../services/userConfig";
import { useCreateAttendee } from "../../api/attendees";
import { useGetPeopleTagsBySubtype } from "../../api/tag";
import { capitalize } from "../../utils/textUtils";
import { Link } from "react-router-dom";

function CreateAttendee() {
  const { user } = useContext(AuthContext);
  const currentWorkspace = getCurrentWorkspace(user);
  const currentEventId = currentWorkspace.eventId;

  const { mutate, isPending, isSuccess } = useCreateAttendee();

  const { data } = useGetPeopleTagsBySubtype({
    eventId: currentEventId,
    subtype: "categoryOfUser",
  });

  const categoryOfUserOptions = data?.map((tag) => ({
    value: String(tag.id),
    label: capitalize(tag.title),
  }));

  const defaultCategoryOfUser = categoryOfUserOptions?.find(
    (tag) => tag.label === "Attendee"
  )?.value;

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      categoryOfUser: defaultCategoryOfUser,
    },
  });

  useEffect(() => {
    if (isSuccess) {
      reset();
    }
  }, [isSuccess, reset]);

  const createAttendee = (data) => {
    const attendee = {
      eventId: currentEventId,
      userData: { ...data },
    };

    mutate(attendee);
  };

  return (
    <Container size={500}>
      <Title>Create Attendee</Title>
      <Paper withBorder shadow="md" p={16} mt={16} radius="md">
        <form onSubmit={handleSubmit((data) => createAttendee(data))}>
          <TextInput
            label="First Name"
            placeholder="First Name"
            type="text"
            name="firstName"
            control={control}
            withAsterisk
            mt="md"
          />
          <TextInput
            label="Last Name"
            placeholder="Last Name"
            type="text"
            name="lastName"
            control={control}
            withAsterisk
            mt="md"
          />
          <TextInput
            label="Email"
            placeholder="Email"
            type="email"
            name="email"
            control={control}
            withAsterisk
            mt="md"
          />
          {/* If there are no categories, display message for creating one */}
          {categoryOfUserOptions?.length > 0 ? (
            <Select
              label="Category"
              placeholder="Category"
              name="categoryOfUser"
              control={control}
              withAsterisk
              mt="md"
              data={categoryOfUserOptions}
            />
          ) : (
            <Text size="sm" c="dimmed" mt="sm">
              No categories available. Please create a category tag
              <Text component={Link} to="/app/tags" c="blue">
                {" "}
                here
              </Text>
              .
            </Text>
          )}

          <Button type="submit" mt="md" loading={isPending}>
            Create Attendee
          </Button>
        </form>
      </Paper>
    </Container>
  );
}

export default CreateAttendee;
