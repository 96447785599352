import {
  IconBrush,
  IconTags,
  IconCubeUnfolded,
  IconMessageChatbot,
  IconTimelineEvent,
  IconUsersGroup,
  IconCalendar,
  IconVersions,
  IconNotebook,
  IconMessage2,
  IconUsers,
} from "@tabler/icons-react";

const links = [
  {
    path: "/app/workspaces",
    label: "Workspaces",
    icon: IconCubeUnfolded,
  },
  {
    path: "/app/events",
    label: "Events",
    icon: IconCalendar,
  },
  {
    path: "/app/users",
    label: "Users",
    icon: IconUsersGroup,
  },
  {
    path: "/app/attendees",
    label: "Attendees",
    icon: IconUsers,
  },
  {
    path: "/app/events/customize",
    label: "Customization",
    icon: IconBrush,
  },
  {
    path: "/app/tags",
    label: "Tags",
    icon: IconTags,
  },
  {
    path: "/app/sessions",
    label: "Sessions",
    icon: IconTimelineEvent,
  },
  {
    path: "/app/polls",
    label: "Polls",
    icon: IconMessageChatbot,
  },
  {
    path: "/app/sponsors",
    label: "Sponsors",
    icon: IconVersions,
  },
  {
    path: "/app/pages",
    label: "Pages",
    icon: IconNotebook,
  },
  { path: "/app/announcements", label: "Announcements", icon: IconMessage2 },
];

export default links;
